export const API_TRAINING_HISTORY_EXPORT = '/api/training-history/export'

/* eslint-disable max-len */
export const API_URL_ACCREDITATION_DELETE = '/api/accreditation-wish/ID/delete'
export const API_URL_ACCREDITATION_SUSPEND = '/api/accreditation-wish/ID/suspend'
export const API_URL_ACCREDITATION_UNDELETE = '/api/accreditation-wish/ID/undelete'
export const API_URL_ACCREDITATION_WISH = '/api/accreditation-wish'
export const API_URL_ACCREDITATION_WISHES_ACTIONS = '/api/accreditation-wish/ID/ACTION'
export const API_URL_ACCREDITATION_WISHES_CRITERION_ACTIONS = '/api/accreditation-wish/prerequisite/OBJECTID/ACTION'
export const API_URL_ACCREDITATION_WISHES_RENEW = '/api/accreditation-wishes/renew'
export const API_URL_ACCREDITATION_WISH_COUNT_POPULATION = '/api/accreditation-wish/count-population'
export const API_URL_ACCREDITATION_WISH_INIT = '/api/accreditation-wish/init'
export const API_URL_ACCREDITATION_WISH_UPDATE = '/api/accreditation-wish/ID'
export const API_URL_ADMIN_ACCREDITATION_SWITCH = '/api/admin/accreditations/switcher'
export const API_URL_ADMIN_ACCREDITATION_WISH_UPLOAD = '/api/admin/accreditation-wish/ID/upload'
export const API_URL_ADMIN_ANIMATION_LOCALE = '/api/admin/animation-locales'
export const API_URL_ADMIN_APPLICATIONS = '/api/admin/applications'
export const API_URL_ADMIN_AUTOMATIONS = '/api/admin/automations'
export const API_URL_ADMIN_DOC_TEMPLATES_TAGS = '/api/admin/doc-templates/tags'
export const API_URL_ADMIN_EMAIL_TEMPLATES_SETTINGS = '/api/admin/email-templates/settings'
export const API_URL_ADMIN_IMPORT_SUBMIT = '/api/admin/import/TYPE/CATEGORY/submit/ID'
export const API_URL_ADMIN_IMPORT_UPLOAD = '/api/admin/import/upload'
export const API_URL_ADMIN_IMPORT_VALIDATE = '/api/admin/import/TYPE/CATEGORY/validate/ID'
export const API_URL_ADMIN_PARAMETERS = '/api/admin/parameters'
export const API_URL_ADMIN_ROOM_BOOKING_SWITCHER = '/api/admin/room-booking/switcher'
export const API_URL_ADMIN_SESSION_TYPES = '/api/admin/session-types'
export const API_URL_ADMIN_SURVEYS = '/api/admin/surveys'
export const API_URL_ADMIN_SURVEY_COPY = '/api/admin/surveys/ID/copy'
export const API_URL_ADMIN_SURVEY_EXPORT = '/api/admin/surveys/ID/export'
export const API_URL_ADMIN_SURVEY_TRIGGER_MAILS = '/api/admin/surveys/ID/trigger-mails'
export const API_URL_ADMIN_TEACHING_METHODS = '/api/admin/teaching-methods'
export const API_URL_ADMIN_TRAINING_BUDGETS = '/api/admin/training-budgets'
export const API_URL_ADMIN_TRAINING_PLANS = '/api/admin/training-plans'
export const API_URL_ADMIN_TRAINING_PROJECTS = '/api/admin/training-projects'
export const API_URL_ADMIN_TRAINING_ROOMS = '/api/admin/session/training-rooms'
export const API_URL_ADMIN_TRAINING_WISH_CANCELLATION_REASONS = '/api/admin/training-wish/cancellation-reasons'
export const API_URL_ADMIN_TRAINING_WISH_DELETE_REASONS = '/api/admin/training-wish/delete-reasons'
export const API_URL_ADMIN_TRAINING_WISH_PERIMETERS = '/api/admin/training-wish/perimeters'
export const API_URL_ADMIN_TRAINING_WISH_REASONS = '/api/admin/training-wish/reasons'
export const API_URL_ADMIN_TRAINING_WISH_REFUSAL_REASONS = '/api/admin/training-wish/refusal-reasons'
export const API_URL_ADMIN_TRAINING_WISH_TYPES = '/api/admin/training-wish/types'
export const API_URL_ADMIN_TRANSLATIONS_DOWNLOAD = '/api/translations/download'
export const API_URL_ANIMATION_LOCALES = '/api/animation-locales'
export const API_URL_BUDGET = '/api/budgets'
export const API_URL_CANCELLATION_REASONS = '/api/cancellation-reasons'
export const API_URL_CATEGORIES = '/api/categories'
export const API_URL_COSTS = '/api/session-costs'
export const API_URL_COMMAND_EDUSIGN_SYNC = '/api/edusign/sync/ID'
export const API_URL_DELETE_REASONS = '/api/delete-reasons'
export const API_URL_DOCUMENTS = '/api/documents/ID'
export const API_URL_DOC_TEMPLATES = '/api/doc-templates'
export const API_URL_DOC_TEMPLATES_ACCREDITATION_PASSPORT_DOWNLOAD = '/api/doc-templates/download/accreditation-passport'
export const API_URL_DOC_TEMPLATES_SESSION_DOWNLOAD = '/api/doc-templates/download/session'
export const API_URL_DOC_TEMPLATES_TRAINING_WISH_CERTIFICATE_DOWNLOAD = '/api/doc-templates/download/training-wish-certificate'
export const API_URL_EMAIL_TEMPLATES = '/api/email-templates'
export const API_URL_EMAIL_TEMPLATES_PREVIEW = '/api/email-templates/preview'
export const API_URL_EMAIL_TEMPLATES_UPLOAD = '/api/email-templates/upload'
export const API_URL_ESTABLISHMENTS = '/api/establishments'
export const API_URL_IMAGE_UPLOAD = '/api/upload/images'
export const API_URL_LOGIN = '/api/login'
export const API_URL_LOGIN_FORGOT_PASSWORD = '/api/public/users/forgot-password'
export const API_URL_LOGIN_FORGOT_PASSWORD_VALIDATE = '/api/public/users/forgot-password/validate'
export const API_URL_LOGIN_UPDATE_PASSWORD = '/api/public/users/update-password'
export const API_URL_MATRIX_EXPORT = '/api/matrix/export'
export const API_URL_MATRIX_FAMILIES = '/api/admin/matrix/family'
export const API_URL_MATRIX_JOBS = '/api/admin/matrix/jobs'
export const API_URL_MATRIX_LEVEL_TEMPLATES = '/api/matrix/level-templates'
export const API_URL_MATRIX_LINK = '/api/matrix/link'
export const API_URL_MATRIX_SKILLS = '/api/admin/skills'
export const API_URL_MATRIX_SKILL_TYPES = '/api/admin/matrix/skill-types'
export const API_URL_NEEDS = '/api/needs'
// @TODO Legacy endpoint
export const API_URL_NEEDS_EXPORT_HISTORY = '/api/training-history/export'
// @TODO Legacy endpoint
export const API_URL_NEEDS_EXPORT_MANAGER = '/api/trainings-wish/manager/export'
export const API_URL_NEEDS_STATUS = '/api/needs/status'
export const API_URL_NEEDS_USER = '/api/needs/by-current-user'
export const API_URL_ORGANIZATION_TYPES = '/api/organization-types'
export const API_URL_PROFILE_SWITCH = '/api/profiles/:id'
export const API_URL_PUBLIC_SURVEY = '/api/public/survey'
export const API_URL_REFUSAL_REASONS = '/api/refusal-reasons'
export const API_URL_SESSION = '/api/sessions'
export const API_URL_SESSION_ATTENDANCE = '/api/sessions/ID/attendance'
export const API_URL_SESSION_ATTENDANCE_SHEET_SEND = '/api/sessions/ID/attendance-sheet/send'
export const API_URL_SESSION_CANCEL = '/api/sessions/ID/cancel'
export const API_URL_SESSION_CANCELLATION_REASONS = '/api/session-cancellation-reasons'
export const API_URL_SESSION_CLOSE = '/api/sessions/ID/close'
export const API_URL_SESSION_COSTS = '/api/sessions/ID/costs'
export const API_URL_SESSION_CSPS = '/api/csps'
export const API_URL_SESSION_DURATION = '/api/sessions/ID/duration'
export const API_URL_SESSION_EXPORT = '/api/sessions/export'
export const API_URL_SESSION_LANGS = '/api/languages'
export const API_URL_SESSION_LONG_PERIODS = '/api/long-sessions/ID/periods'
export const API_URL_SESSION_ORGANIZERS = '/api/session/organizers'
export const API_URL_SESSION_PARTICIPANTS_TRAINING_WISHES = '/api/session-participants/ID/training-wishes'
export const API_URL_SESSION_PARTICIPANTS_USERS = '/api/session-participants/ID/users'
export const API_URL_SESSION_PARTICIPANT_MAILS = '/api/session-participants/mails'
export const API_URL_SESSION_PARTICIPANT_MAILS_SENDMAIL = '/api/session-mail/send'
export const API_URL_SESSION_PARTICIPANT_SURVEYS = '/api/session-participants/surveys'
export const API_URL_SESSION_PARTICIPANT_SURVEYS_SENDMAIL = '/api/session-survey/send'
export const API_URL_SESSION_PERIOD = '/api/session-period/ID'
export const API_URL_SESSION_PERIODS = '/api/session-periods'
export const API_URL_SESSION_PERIODS_HOURS = '/api/sessions/ID/periods/distinct-hours'
export const API_URL_SESSION_PERIODS_HOURS_POST = '/api/session-periods/distinct-hours'
export const API_URL_SESSION_PERIODS_ROOMS = '/api/sessions/ID/periods/rooms'
export const API_URL_SESSION_PERIODS_TRAINERS_ORGANIZATIONS = '/api/sessions/ID/periods/trainers-organizations'
export const API_URL_SESSION_REGISTERED_PARTICIPANTS = '/api/session-participants/registered-marked'
export const API_URL_SESSION_TRAININGS = '/api/session/trainings'
export const API_URL_SESSION_TRIGGER_EMAIL = '/api/sessions/ID/trigger-mail'
export const API_URL_SESSION_TRIGGER_EMAILS_COLLABORATOR = '/api/sessions/trigger-mails/ID/email-collaborator'
export const API_URL_SESSION_TRIGGER_EMAILS_MANAGER = '/api/sessions/trigger-mails/ID/email-manager'
export const API_URL_SESSION_TRIGGER_EMAIL_UPDATE = '/api/sessions/trigger-mails'
export const API_URL_SESSION_TYPES = '/api/session-types'
export const API_URL_SESSION_UNCLOSE = '/api/sessions/ID/unclose'
export const API_URL_SESSION_UNREGISTER = '/api/training-wishes/ID/unregister-admin'
export const API_URL_SITES = '/api/sites'
export const API_URL_SSO_LOGIN = '/api/saml/login'
export const API_URL_SSO_LOGOUT = '/api/saml/logout'
export const API_URL_SURVEYS = '/api/surveys'
export const API_URL_TEACHING_METHODS = '/api/teaching-methods'
export const API_URL_THEMES = '/api/themes'
export const API_URL_TRAINERS = '/api/users?trainer=true'
export const API_URL_TRAINERS_ATTENDANCE = '/api/public/trainers/attendance/TOKEN'
export const API_URL_TRAININGS = '/api/trainings'
export const API_URL_TRAININGS_PDF = '/api/trainings/pdf'
export const API_URL_TRAINING_ARCHIVE = '/api/trainings/ID/newArchive'
export const API_URL_TRAINING_BUDGETS = '/api/training-budgets'
export const API_URL_TRAINING_CANCEL = '/api/trainings/ID/newDelete'
export const API_URL_TRAINING_CATEGORIES = '/api/admin/training-categories'
export const API_URL_TRAINING_COPY = '/api/trainings/ID/copy'
export const API_URL_TRAINING_PDF = '/api/trainings/ID/pdf'
export const API_URL_TRAINING_PLANS = '/api/training-plans'
export const API_URL_ASYNC_EXPORT = '/api/async-export/ID'
export const API_URL_ASYNC_EXPORT_ELEMENT = '/api/async-export/:type/:id'
export const API_URL_TRAINING_PLAN_CERTIFICATE_TEMPLATE = '/api/training-wishes/ID/certificate-template'
export const API_URL_TRAINING_PLAN_HISTORY = '/api/training-wishes/ID/history'
export const API_URL_TRAINING_PLAN_REGISTER = '/api/training-plan/training-wishes/ID/register'
export const API_URL_TRAINING_PLAN_SESSIONS = '/api/training-plan/training-wishes/ID/sessions'
export const API_URL_TRAINING_PLAN_UNREGISTER = '/api/training-wishes/ID/unregister'
export const API_URL_TRAINING_PLAN_UPDATE_TRAINING = '/api/training-plan/training-wishes/ID/update-training'
export const API_URL_TRAINING_PLAN_UPDATE_TRAININGS = '/api/training-plan/training-wishes/massupdate-trainings'
export const API_URL_TRAINING_PLAN_UPDATE_USER = '/api/training-plan/training-wishes/ID/update-user'
export const API_URL_TRAINING_PLAN_UPDATE_VINTAGE = '/api/training-plan/training-wishes/massupdate-vintage'
export const API_URL_TRAINING_PROJECTS = '/api/training-projects'
// @TODO Port to APIP
export const API_URL_TRAINING_REASONS = '/api/training-wish/reasons'
export const API_URL_TRAINING_ROOMS = '/api/training-rooms'
export const API_URL_TRAINING_ROOMS_CATEGORIES = '/api/training-room-categories'
export const API_URL_TRAINING_THEMES = '/api/admin/training-themes'
export const API_URL_TRAINING_VALIDATE = '/api/trainings/ID/validate'
export const API_URL_TRAINING_WISH = '/api/training-wishes'
export const API_URL_TRAINING_WISHES = '/api/training-wishes'
export const API_URL_TRAINING_WISHES_PATHWAY = '/api/training-wishes/pathway'
export const API_URL_TRAINING_WISH_CANCEL = '/api/training-wishes/ID/cancel'
export const API_URL_TRAINING_WISH_CONFIRM = '/api/training-wishes/ID/confirm'
export const API_URL_TRAINING_WISH_COUNT_POPULATION = '/api/training-wishes/count-population'
export const API_URL_TRAINING_WISH_DELAY = '/api/training-wishes/ID/delay'
// @TODO Legacy endpoint
export const API_URL_TRAINING_WISH_DELAY_MULTIPLE = '/api/training-wishes/delay'
export const API_URL_TRAINING_WISH_DELETE = '/api/training-wishes/ID/delete'
// @TODO Legacy endpoint
export const API_URL_TRAINING_WISH_DELETE_MULTIPLE = '/api/training-wishes/delete'
export const API_URL_TRAINING_WISH_DETAILS = '/api/training-wishes/ID/view'
export const API_URL_TRAINING_WISH_FREE = '/api/training-wishes/free'
export const API_URL_TRAINING_WISH_GRANT = '/api/training-wishes/ID/allow'
// @TODO Legacy endpoint
export const API_URL_TRAINING_WISH_GRANT_MULTIPLE = '/api/training-wishes/allow'
export const API_URL_TRAINING_WISH_ORIENTATIONS = '/api/training-wish/orientations'
export const API_URL_TRAINING_WISH_PATHWAY = '/api/training-wish-pathway/ID'
export const API_URL_TRAINING_WISH_PERIMETERS = '/api/training-wish/perimeters'
export const API_URL_TRAINING_WISH_PROVISION = '/api/training-wishes/ID/provision'
export const API_URL_TRAINING_WISH_PROVISION_USERSEARCH = '/api/training-wishes/ID/users'
export const API_URL_TRAINING_WISH_REACTIVATE = '/api/training-wishes/ID/uncancel'
export const API_URL_TRAINING_WISH_REASONS = '/api/admin/training-wish/reasons'
export const API_URL_TRAINING_WISH_REFUSE = '/api/training-wishes/refuse'
export const API_URL_TRAINING_WISH_TYPES = '/api/training-wish/types'
export const API_URL_USERS = '/api/users'
export const API_URL_USERS_EXTERNAL = '/api/trainers/external'
export const API_URL_USERS_SIMPLE = '/api/users'
export const API_URL_USER_ACCREDITATION_PASSPORT_TEMPLATE = '/api/users/accreditation-passport-template'
export const API_URL_USER_FILTERS = '/api/user-filters'
export const API_URL_USER_INITIALS = '/api/user-initials'

export const COLLECTIVE_STATUS = {
  INITIED: 'initied',
  ALLOWED: 'allowed',
  TO_REVIEW: 'to_review',
  PENDING: 'pending',
  PENDING_CONFIRMED: 'pending_confirmed',
  DISALLOWED: 'disallowed',
  DISALLOWED_CONFIRMED: 'disallowed_confirmed',
  CANCELED: 'canceled',
  REGISTERED: 'registered',
  ACCOMPLISHED: 'accomplished',
  UNACCOMPLISHED: 'unaccomplished',
}

export const SESSION_STATE = {
  OPEN: 'open',
  ACCOMPLISHED: 'accomplished',
  CLOSED: 'closed',
  CANCELED: 'canceled',
}

export const WISH_STATUS = {
  ALLOWED: 'allowed',
  CANCELED: 'canceled',
  DISALLOWED: 'disallowed',
  INITIED: 'initied',
  PENDING: 'pending',
  UNACCOMPLISHED: 'unaccomplished',
}
